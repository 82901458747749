import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { motion } from "framer-motion";

import Layout from "../components/layout";
import SEO from "../components/seo";

import TmpImg from "../assets/images/tmp/tmp_article.jpg";

const PostWrapper = styled.div`
  margin-top: 90px;
  font-family: "GT Walsheim", sans-serif;
`;

const HeaderImage = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`;

const ImageWrapper = styled.div`
  grid-column: 1 / -1;
  @media (min-width: 1180px) {
    grid-column: span 8 / -1;
  }
  @media (min-width: 768px) {
    grid-column: span 10 / -1;
  }
`;

const HeaderIntro = styled.div`
  z-index: 5;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @media (min-width: 1180px) {
    margin-top: -120px;
  }
  @media (min-width: 768px) {
    margin-top: -80px;
  }
  @media (max-width: 768px) {
    display: block;
    margin-top: -70px;
    width: 95%;
    margin-left: 2.5%;
  }
`;

const HeaderTextContainer = styled.div`
  grid-column: -1;
  background-color: #ffffff;
  border-radius: 4px;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  @media (min-width: 1180px) {
    grid-column: 2 / span 7;
  }
  @media (min-width: 768px) {
    grid-column: span 9;
  }
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Title = styled.h1`
  font-weight: 800;
  font-size: 48px;
  line-height: 55px;
  color: #2c3445;
  margin-bottom: 5px;
  @media (max-width: 900px) {
    font-size: 38px;
    line-height: 48px;
  }
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

const Subtitle = styled.h2`
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  color: #36313d;
  span {
    font-weight: 600;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 30px;
  }
`;

const Content = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  color: #2b2a35;
  margin: 0px 35px;
  span {
    font-size: 21px;
    line-height: 38px;
    font-weight: 700;
  }
  @media (max-width: 768px) {
    margin: 0px 20px;
  }
`;

const InlineImg = styled.div`
  max-width: 100%;
  max-height: 100%;
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <PostWrapper>
      <HeaderImage>
        <ImageWrapper>
          <img src={TmpImg} />
        </ImageWrapper>
      </HeaderImage>

      <HeaderIntro>
        <HeaderTextContainer>
          <Title>
            This is a much much longer title, just like the ones Clair likes to
            write haha
          </Title>
          <Subtitle>
            Words by <span>Josh Cawthorne</span> | 10th August, 2020
          </Subtitle>
        </HeaderTextContainer>
      </HeaderIntro>
      <Content>
        <p>
          <span>
            Most bee jobs are small ones. But bees know that every small job, if
            it's done well means a lot. But choose carefully because you'll stay
            in the job you pick for the rest of your life.
          </span>
        </p>
        <p>
          Our honey is being brazenly stolen on a massive scale! This is worse
          than anything bears have done! I intend to do something. Oh, Barry,
          stop. Who told you humans are taking our honey? That's a rumor. Do
          these look like rumors? That's a conspiracy theory. These are
          obviously doctored photos.
        </p>
        <InlineImg>
          <img src={TmpImg} />
        </InlineImg>
        <p>
          Tonight we're talking to Barry Benson. Did you ever think, "I'm a kid
          from the hive. I can't do this"? Bees have never been afraid to change
          the world. What about Bee Oolumbus? Bee Gandhi? Bejesus? Where I'm
          from, we'd never sue humans. We were thinking of stickball or candy
          stores.{" "}
        </p>
      </Content>
    </PostWrapper>
  </Layout>
);

export default IndexPage;
